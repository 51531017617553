import React, {useState} from 'react'
import Img from 'gatsby-image'
import styles from './caseline.module.css'

const CaseLine = ({onClick, title, tagline, image, slug, type, city, url, selected}) => {

  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [innerWidth, setInnerWidth] = useState(0);
  const [innerHeight, setInnerHeight] = useState(0);

  return (
    <li onClick={() => onClick()} className={styles.caseLine + ' ' + (selected ? styles.selected : ' ')} onMouseMove={(e) => {setMouseX(e.clientX); setMouseY(e.clientY); setInnerWidth(window.innerWidth); setInnerHeight(window.innerHeight);}}>
      <a href={slug ? "/cases/" + slug + "/" : url} target={slug ? "_self" : "_blank"}>
        <p className={styles.client}>{title}</p>
        <p>{tagline}</p>
        <p>
          {type.map((node) => {
            return (
              <span className={styles.type}>{node}</span>
            )
          })}
        </p>
        <p className='hide-sm'>{city}</p>
        <div className={styles.caseImage + " " + ((mouseY >= innerHeight - 372) ? styles.bottomAnchor : "")} style={
        {
            top: (mouseY < innerHeight - 372) ? mouseY + 1 : mouseY - 332, 
            left: (mouseX < innerWidth - 620) ? mouseX + 20 : innerWidth - 600
        }
        }><Img  alt={image.title} durationFadeIn={0} fluid={{...image.fluid, aspectRatio: 1.7}} loading="lazy"/>
        <span>{title}</span>
        </div>
      </a>
  </li>
  );
}

export default CaseLine