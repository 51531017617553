import React, { PureComponent, useState, useContext } from 'react'
import Img from 'gatsby-image'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import "slick-carousel/slick/slick-theme.css";
import styles from './featuredCases-section.module.css'

const FeaturedCasesSection = ({featuredCases, style}) => {

    return(
      <section style={style} className={styles.featuredCases}>    
        <Slider {...{
            autoplay: true,
            autoplaySpeed: 6000,
            arrows: false,
            dots: true,
            mobileFirst: true
            }}>
            {featuredCases.map((node,index) => { 
                return(
                    <div key={index} className={styles.featuredCase}>
                        <Img
                            alt={node.image.title}
                            fluid={node.image.fluid}
                            durationFadeIn={0}
                            style={{ position: `absolute`, top: 0, left: 0, width: "100%", height: "100%", zIndex: -1}}
                            loading="lazy"
                        />
                        <div className={styles.featuredCaseContent}>
                            <div className={styles.featuredCaseContentBody} dangerouslySetInnerHTML={{
                                __html: node.body.childMarkdownRemark.html
                            }}></div>
                            <a href={"/cases/" + node.case.slug} className={styles.featuredCaseLink}>go to case</a>
                        </div>
                    </div>
                )
            })}
        </Slider>
      </section>
    )
}

export default FeaturedCasesSection