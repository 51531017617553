import React, {useState} from 'react'
import CaseLine from '../components/case-line'
import styles from './caselist.module.css'

const CaseList = ({cases}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  function handleClick(index) {
    setSelectedIndex(index);
  }

  return (
    <ul className={styles.caseList}>
          {cases.map((node,index) => {
            return (
                <CaseLine key={index} onClick={() => handleClick(index)} title={node.client} tagline={node.tagline ? node.tagline : node.header} image={node.heroImage ? node.heroImage : node.image} slug={node.slug} url={node.url} type={node.type} city={node.city} selected={index == selectedIndex}/>
            )
          })}
    </ul>
  );
}

export default CaseList