import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import MediaQuery from 'react-responsive'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CaseList from '../components/case-list'
import styles from '../components/caselist.module.css'
import FeaturedCasesSection from '../components/sections/featuredCases-section'

class RootIndex extends React.Component {
  
  render() {
    const cases = get(this, 'props.data.cases')
    const caseLinks = get(this, 'props.data.caseLinks')
    const combinedCases = cases.nodes.concat(caseLinks.nodes)
    const page = get(this, 'props.data.page')

    return (
      <Layout location={this.props.location} wideNav noFooter>
        <SEO title={page.title} description={page.subtitle}></SEO>
        <h1 className={styles.caseListHeader}>Our heritage</h1>
        <MediaQuery query="(min-width: 1024px)">
          <FeaturedCasesSection style={{margin: 0, 'maxHeight': '60vh'}} featuredCases={this.props.data.featuredCases.nodes}/>
        </MediaQuery>
        <CaseList cases={combinedCases}/>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query CasesQuery {
    site {
      siteMetadata {
        title
      }
    }

    page: contentfulPage(name: {eq: "Work"}) {
      title
      subtitle
      body {
        childContentfulRichText {
          html
        }
      }
    }

    cases: allContentfulCase(
      filter: { node_locale: { eq: "en-US" } }, 
      sort: {fields:[released],order:DESC}),{
      nodes {
        slug
        heroImage {
          title
          fluid (maxWidth: 600, quality: 70, sizes: "(max-width: 400px) 150px, (max-width: 1024) 300px, 600px"){
            ...GatsbyContentfulFluid_withWebp
          }
        }
        header
        subheader
        tagline
        type
        client
        city
        createdAt
      }
    }

    caseLinks: allContentfulCaseLink(
      filter: { node_locale: { eq: "en-US" } }, 
      sort: {fields:[createdAt],order:DESC}),{
      nodes {
        image {
          title
          fluid (maxWidth: 600, quality: 70, sizes: "(max-width: 400px) 150px, (max-width: 1024) 300px, 600px"){
            ...GatsbyContentfulFluid_withWebp
          }
        }
        header
        type
        client
        city
        url
        createdAt
      }
    }

    featuredCases: allContentfulFeaturedCase(
      filter: { node_locale: { eq: "en-US" } },
      sort: {fields:[createdAt],order:DESC}),{
      nodes {
        image {
          title
          fluid (maxWidth: 1920, quality: 70, sizes: "(max-width: 480px) 480px, (max-width: 960px) 960px, 1920px"){
            ...GatsbyContentfulFluid_withWebp
          }
        }
        body {
          childMarkdownRemark {
            html
          }
        }
        case {
          slug
        }
      }
    }
  }
`
